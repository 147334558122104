<template>
<div class="container-fluid">
  <div class="p-lg-2">
    <h2 class="roboto-bold">Store Status</h2>
    <b-form class="pt-5" @submit.prevent="submitData">
      <div class="row mb-2">
        <div class="col-auto col-md-2 pt-1">Close Your Store <b style="color: red;">*</b></div>
        <div class="col col-md-5"><b-form-checkbox switch size="lg" v-model="$v.formData.is_closed.$model" /></div>
      </div>
      <div class="row mb-2">
        <div class="col-md-2 pt-2">Closure Date <b v-if="formData.is_closed" style="color: red;">*</b></div>
        <div class="col-md-5">
          <div class="row no-gutters" :class="{'bg-disabled' : !formData.is_closed}">
            <div class="col">
              <InputDate
                v-model="$v.formData.start_date_close.$model"
                :min="formatDate(new Date)"
                :disabled="!formData.is_closed"
              />
            </div>
            <div class="col-auto px-2 pt-2">to</div>
            <div class="col">
              <InputDate
                v-model="$v.formData.end_date_close.$model"
                :min="formData.start_date_close"
                :disabled="!formData.is_closed"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-2 pt-2">Note</div>
        <div class="col-md-5">
          <InputText
            type="textarea"
            v-model="$v.formData.closed_notes.$model"
            placeholder="Please leave a note here for customers before closing your store."
            :disabled="!formData.is_closed"
          />
        </div>
      </div>
      <hr />
      <div class="text-right">
        <button class="btn btn-info">
          Save <font-awesome icon="spinner" spin v-if="isLoading"/>
        </button>
      </div>
    </b-form>
  </div>
</div>
</template>
<script>
import { toaster, cookieMan } from '@/_helpers';
const InputText = () => import('@/components/formCustom/InputText');
const InputDate = () => import('@/components/formCustom/InputDate');

import { required, requiredIf } from 'vuelidate/lib/validators';
import moment from 'moment';

export default {
  name: 'ManageStore',
  components: {
    InputText,
    InputDate,
  },
  data() {
    return {
      isLoading: false,
      formData: {
        is_closed: false,
        closed_notes: null,
        start_date_close: null,
        end_date_close: null,
      }
    }
  },
  validations() {
    return {
      formData: {
        is_closed: {
          required
        },
        closed_notes: {
          // required: requiredIf(() => this.formData.is_closed)
        },
        start_date_close: {
          required: requiredIf(() => this.formData.is_closed)
        },
        end_date_close: {
          required: requiredIf(() => this.formData.is_closed)
        }
      }
    }
  },
  computed: {
    merchantData() {
      return this.$store.getters.getMerchantData;
    },
  },
  mounted() {
    this.mountData();
  },
  methods: {
    mountData() {
      this.formData.start_date_close = this.merchantData.merchant_start_date_close ? new Date(parseInt(this.merchantData.merchant_start_date_close)) : null
      this.formData.end_date_close = this.merchantData.merchant_end_date_close ? new Date(parseInt(this.merchantData.merchant_end_date_close)) : null
      this.formData.closed_notes = this.merchantData.merchant_closed_notes
      // const today = new Date()
      // if (this.formData.start_date_close && this.formData.end_date_close)
        // this.formData.is_closed = today >= this.formData.start_date_close && this.formData.end_date_close >= today
      this.formData.is_closed = [this.formData.start_date_close, this.formData.end_date_close].some(el => el);
    },
    async submitData() {
      this.$v.$touch()
      if (this.$v.$error) {
        toaster.make('Please fill in the form correctly', 'danger')
        console.error(Object.keys(this.$v.formData).map(el => {
          if (el.indexOf("$") < 0 && this.$v.formData[el].$invalid) 
            return {
              [el]: this.$v.formData[el]
            }
        }).filter(el => el))
        return
      } else if (!this.formData.is_closed) {
        this.formData.closed_notes = ''
        this.formData.start_date_close = null
        this.formData.end_date_close = null
      }
      this.isLoading = true;
      try {
        const res = await this.$api.user.updateStore(this.formData)
        if (res.status === 200) {
          this.refreshMerchantData(res.data.data)
          toaster.make(res.data.message, 'success')
        } else toaster.make(res.data.message, 'danger')
      } catch (e) {
        if (e.response) toaster.make(e.response, 'danger')
        console.error(e);
      }
      this.isLoading = false;
    },
    setCookie(obj) {
      for (let o in obj) 
        cookieMan.put(o, obj[o] || "")
    },
    refreshMerchantData(data) {
      const retval = {
        quickblox_token: this.merchantData.quickblox_token,
        quickblox_id: data.quickblox_id,
        merchant_id: data.id,
        merchant_access_token: this.merchantData.merchant_access_token,
        merchant_expired_date: this.merchantData.merchant_expired_date,
        merchant_first_name: data.first_name,
        merchant_last_name: data.last_name,
        merchant_full_name: data.full_name,
        merchant_email: data.email,
        merchant_provider: "email",
        merchant_type: data.merchant_type,
        merchant_account_alias: data.account_alias,
        merchant_account_number: data.account_number,
        merchant_url: data.url,
        merchant_phone: data.phone,
        merchant_couriers: JSON.stringify(data.couriers.map(({ id }) => JSON.parse(`{"shipping_id":${id}}`))),
        merchant_address: data.address,
        merchant_region: JSON.stringify(data.region),
        merchant_district: JSON.stringify(data.district),
        merchant_township: JSON.stringify(data.township),
        merchant_postal: data.postal_code,
        // merchant close store feature
        merchant_closed_notes: data.closed_notes,
        merchant_start_date_close: data?.start_date_close_epoch,
        merchant_end_date_close: data?.end_date_close_epoch,
        // merchant pick up point platform name
        merchant_pick_up_point_platform: JSON.stringify(this.merchantData.merchant_pick_up_point_platform)
      }
      this.setCookie(retval)
      // set initial state for vuex store because the data type is object / array (in retval the value for the data is JSON stringfy)
      retval['merchant_couriers'] = data.couriers.map(({ id }) => JSON.parse(`{"shipping_id":${id}}`))
      retval['merchant_region'] = data.region
      retval['merchant_district'] = data.district
      retval['merchant_township'] = data.township
      this.$store.dispatch("setMerchantData", retval)
    },
    formatDate(value, format = 'YYYY-MM-DD') {
      return value ? moment(String(value)).format(format).toString() : value
    },
  },
}
</script>
